.App {
  text-align: center;
}

.nav-links {
  width: 50%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
}

.search-bar {
  display: flex;
  min-width: 240px;
  max-width: 360px;
  flex-grow: 1;
  padding-left: 20px;
  padding-right: 20px;
}

.nav-bar-left {
  display: flex;
  justify-content: flex-start;
  flex-grow: 1;
  align-items: center;
}

.nav-bar-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.type-bar {
  flex-grow: 1;
}

.logo-text {
  font-family: "Righteous";
  font-size: 28px;
  color: #0e7f8a;
}

.red-hat-display {
  font-family: "SuperaGothic";
}

.red-hat-display-bold {
  font-family: "SuperaGothic";
  font-weight: bold;
}

.supera-gothic {
  font-family: "SuperaGothic";
}

.supera-gothic-bold {
  font-family: "SuperaGothic";
  font-weight: bold;
}

.roboto-condensed-bold {
  font-family: "RobotoCondensed";
  font-weight: bold;
}

.roboto-condensed {
  font-family: "RobotoCondensed";
}

.line-clamp-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  height: 3.8em;
  line-height: 1.3;
}

.page-container {
  flex-grow: 1;
  width: auto;
}

.required:after {
  content: " *";
  color: red;
}

input[type="password"]::-webkit-contacts-auto-fill-button,
input[type="password"]::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  height: 0;
  width: 0;
  margin: 0;
}

.scroll-top {
  scroll-margin-top: 125px;
}

.test-scroll-top {
  scroll-margin-top: 105px;
}
