.Rectangle_6_833 {
  animation: 2.5s linear 0s 1 normal forwards running Rectangle_6_833;
}
@keyframes Rectangle_6_833 {
  0% {
    width: 1px;
  }
  48% {
    width: 1px;
  }
  72% {
    width: 1594px;
  }
  100% {
    width: 1594px;
  }
}
.Rectangle_7_30 {
  animation: 2.5s linear 0s 1 normal forwards running Rectangle_7_30;
}
@keyframes Rectangle_7_30 {
  0% {
    width: 1px;
  }
  48% {
    width: 1px;
  }
  72% {
    width: 1594px;
  }
  100% {
    width: 1594px;
  }
}
.Rectangle_8_819 {
  animation: 2.5s linear 0s 1 normal forwards running Rectangle_8_819;
}
@keyframes Rectangle_8_819 {
  0% {
    width: 1px;
  }
  48% {
    width: 1px;
  }
  72% {
    width: 1594px;
  }
  100% {
    width: 1594px;
  }
}
.Rectangle_9_232 {
  animation: 2.5s linear 0s 1 normal forwards running Rectangle_9_232;
}
@keyframes Rectangle_9_232 {
  0% {
    width: 1px;
  }
  48% {
    width: 1px;
  }
  72% {
    width: 1594px;
  }
  100% {
    width: 1594px;
  }
}
.Rectangle_10_977 {
  animation: 2.5s linear 0s 1 normal forwards running Rectangle_10_977;
}
@keyframes Rectangle_10_977 {
  0% {
    width: 1px;
  }
  48% {
    width: 1px;
  }
  72% {
    width: 1594px;
  }
  100% {
    width: 1594px;
  }
}
.Rectangle_11_809 {
  animation: 2.5s linear 0s 1 normal forwards running Rectangle_11_809;
}
@keyframes Rectangle_11_809 {
  0% {
    width: 1px;
  }
  80% {
    width: 1px;
  }
  88% {
    width: 2200px;
  }
  100% {
    width: 2200px;
  }
}
.Rectangle_1_835 {
  animation: 2.5s linear 0s 1 normal forwards running Rectangle_1_835;
}
@keyframes Rectangle_1_835 {
  0% {
    width: 1px;
  }
  48% {
    width: 971px;
  }
  100% {
    width: 971px;
  }
}
.Rectangle_2_983 {
  animation: 2.5s linear 0s 1 normal forwards running Rectangle_2_983;
}
@keyframes Rectangle_2_983 {
  0% {
    width: 1px;
  }
  48% {
    width: 584px;
  }
  100% {
    width: 584px;
  }
}
.Rectangle_3_743 {
  animation: 2.5s linear 0s 1 normal forwards running Rectangle_3_743;
}
@keyframes Rectangle_3_743 {
  0% {
    width: 1px;
  }
  48% {
    width: 772px;
  }
  100% {
    width: 772px;
  }
}
.Rectangle_4_963 {
  animation: 2.5s linear 0s 1 normal forwards running Rectangle_4_963;
}
@keyframes Rectangle_4_963 {
  0% {
    width: 1px;
  }
  48% {
    width: 1242px;
  }
  100% {
    width: 1242px;
  }
}
.Rectangle_5_33 {
  animation: 2.5s linear 0s 1 normal forwards running Rectangle_5_33;
}
@keyframes Rectangle_5_33 {
  0% {
    width: 1px;
  }
  48% {
    width: 1491px;
  }
  100% {
    width: 1491px;
  }
}
.Group_835_520 {
  animation: 2.5s linear 0s 1 normal forwards running Group_835_520;
}
@keyframes Group_835_520 {
  0% {
    opacity: 0;
  }
  72% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
.Group_839_737 {
  animation: 2.5s linear 0s 1 normal forwards running Group_839_737;
}
@keyframes Group_839_737 {
  0% {
    opacity: 0;
  }
  72% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
.Group_838_567 {
  animation: 2.5s linear 0s 1 normal forwards running Group_838_567;
}
@keyframes Group_838_567 {
  0% {
    opacity: 0;
  }
  72% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
.Group_837_711 {
  animation: 2.5s linear 0s 1 normal forwards running Group_837_711;
}
@keyframes Group_837_711 {
  0% {
    opacity: 0;
  }
  72% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
.Group_836_576 {
  animation: 2.5s linear 0s 1 normal forwards running Group_836_576;
}
@keyframes Group_836_576 {
  0% {
    opacity: 0;
  }
  72% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
.Group_841_743 {
  animation: 2.5s linear 0s 1 normal forwards running Group_841_743;
}
@keyframes Group_841_743 {
  0% {
    opacity: 0;
  }
  88% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
