body {
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
  width: 100%;
}

html {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Righteous";
  src: local("Righteous"), url(./fonts/Righteous-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "RedHatDisplay";
  src: local("RedHatDisplay-Regular"),
    url(./fonts/RedHatDisplay-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "RedHatDisplay";
  font-weight: bold;
  src: local("RedHatDisplay-Bold.ttf"),
    url(./fonts/RedHatDisplay-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "RedHatDisplay-Bold";
  src: local("RedHatDisplay-Bold.ttf"),
    url(./fonts/RedHatDisplay-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "RedHatDisplay-Medium";
  src: local("RedHatDisplay-Medium.ttf"),
    url(./fonts/RedHatDisplay-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "RedHatDisplay-SemiBold";
  font-weight: bold;
  src: local("RedHatDisplay-SemiBold.ttf"),
    url(./fonts/RedHatDisplay-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "RobotoCondensed";
  src: local("RobotoCondensed-Regular.ttf"),
    url(./fonts/RobotoCondensed-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "RobotoCondensed";
  font-weight: bold;
  src: local("RobotoCondensed-Bold.ttf"),
    url(./fonts/RobotoCondensed-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "SuperaGothic";
  src: local("RedHatDisplay-Regular"),
    url(./fonts/RedHatDisplay-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "SuperaGothic";
  font-weight: bold;
  src: local("RedHatDisplay-Medium.ttf"),
    url(./fonts/RedHatDisplay-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "SuperaGothic-ExtraBold";
  font-weight: bold;
  src: local("RedHatDisplay-Bold.ttf"),
    url(./fonts/RedHatDisplay-Bold.ttf) format("truetype");
}
