/* Demo.css */

.file-drop {
  /* relatively position the container bc the contents are absolute */

  width: 100%;
  height: auto;
}

.file-drop > .file-drop-target {
  min-height: 200px;
  outline: dashed 1px #0c8089;
  background-color: #4daab3;
  /* basic styles */
  top: 0;
  left: 0;

  width: 100%;
  border-radius: 2px;

  /* horizontally and vertically center all content */
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;

  flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;

  align-items: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;

  justify-content: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;

  align-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;

  text-align: center;
}

.file-drop > .file-drop-target.file-drop-dragging-over-frame {
  /* overlay a black mask when dragging over the frame */
  border: none;
  background-color: #3897a0;
  box-shadow: none;
  z-index: 50;
  opacity: 1;

  /* typography */
  color: white;
}

.file-drop > .file-drop-target.file-drop-dragging-over-target {
  /* turn stuff orange when we are dragging over the target */
  color: #3897a0;
  box-shadow: 0 0 13px 3px #4daab3;
}
