.carousel-with-custom-dots {
  margin-top: 100px;
  padding-bottom: 100px;
}
.custom-dot {
  flex: 1 1 auto;
  border-radius: 15px;
}
.active {
  background-color: rgba(14, 127, 138, 0.9);
  box-shadow: inset 2px 2px 6px rgba(255, 255, 255, 0.3);
}
.dot-list {
  height: 6px;
  margin-top: 15px;
  margin-bottom: 20px;
  position: relative;
  justify-content: space-evenly;
  background-color: rgba(56, 185, 194, 0.05);
  box-shadow: rgb(0 0 0 / 5%) 2px 2px 10px inset;
  border-radius: 15px;
  align-items: center;
}

@media screen and (max-width: 600px) {
  .dot-list {
    margin-left: 10px;
    margin-right: 10px;
    background-color: rgba(14, 127, 138, 0.1);
  }
}

.right-arrow {
  position: absolute;
  right: -30px;
}
.left-arrow {
  position: absolute;
  left: -30px;
}
